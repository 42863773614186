const arr = [
  "😀", "😃", "😄", "😁",
  "😆", "😅", "🤣", "😂",
  "🙂", "🙃", "🫠", "😉",
  "😊", "😇", "🥰", "😍",
  "🤩", "😘", "😗", "☺️",
  "😚", "😙", "🥲", "😋",
  "😛", "😜", "🤪", "😝",
  "🤑", "🤗", "🤭", "🫢",
  "🫣", "🤫", "🤔", "🫡",
  "🤐", "🤨", "😐", "😑",
  "😶", "🫥", "😶‍🌫️", "😏",
  "😒", "🙄", "😬", "😮‍💨",
  "🤥", "😌", "😔", "😪",
  "🤤", "😴", "😷", "🤒",
  "🤕", "🤢", "🤮", "🤧",
  "🥵", "🥶", "🥴", "😵",
  "😵‍💫", "🤯", "🤠", "🥳",
  "🥸", "😎", "🤓", "🧐",
  "😕", "🫤", "😟", "🙁",
  "☹️", "😮", "😯", "😲",
  "😳", "🥺", "🥹", "😦",
  "😧", "😨", "😰", "😥",
  "😢", "😭", "😱", "😖",
  "😣", "😞", "😓", "😩",
  "😫", "🥱", "😤", "😡",
  "😠", "🤬", "😈", "👿",
  "💀", "☠️", "💩", "🤡",
  "👹", "👺", "👻", "👽",
  "👾", "🤖", "😺", "😸",
  "😹", "😻", "😼", "😽",
  "🙀", "😿", "😾", "🙈",
  "🙉", "🙊", "💋", "💌",
  "💘", "💝", "💖", "💗",
  "💓", "💞", "💕", "💟",
  "❣️", "💔", "❤️‍🔥", "❤️‍🩹",
  "❤️", "🧡", "💛", "💚",
  "💙", "💜", "🤎", "🖤",
  "🤍", "💯", "💢", "💥",
  "💫", "💦", "💨", "🕳️",
  "💣", "💬", "👁️‍🗨️", "🗨️",
  "🗯️","💭","💤",
];

export default arr;