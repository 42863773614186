<template>
  <component :is="currentComponent"></component>
</template>

<script>
import LiveAli from './components/LiveAli.vue';
import LiveVolcano from "./components/LiveVolcano.vue";
export default {
  components: {
    LiveAli,
    LiveVolcano
  },
  data() {
    return {
      currentComponent: "LiveVolcano"
    }
  }
}
</script>
