<template>
  <div class="live">
    <div class="live-left">
      <div class="live-header">
        <div class="header-left">
          <el-button class="black-btn" size="small" @click="returnBack()">返回</el-button>
          <div class="live-name">{{ liveRoomInfo.roomName }}</div>
        </div>
        <div class="header-right">
          <div class="class-time" v-if="this.classTimer">已上课 {{ utils.secondsToMS(classTime) }}</div>
          <el-button v-if="liveRoomInfo.isHost" :loading="classLoading" :type="classStatus ? 'danger' : 'primary'"
            size="small" @click="switchClass()">{{ classStatus ?
              "下课" : "上课" }}</el-button>
          <el-button v-if="liveRoomInfo.isHost || liveRoomInfo.isLecturer" size="small" :loading="liveLoading"
            :type="liveStatus ? 'danger' : 'primary'" @click="switchLive()">{{ liveStatus ?
              "结束直播" : "开始直播" }}</el-button>
        </div>
      </div>
      <div class="screen-sharing-display-area" ref="videos">
        <!-- （老师端课件展示） -->
        <div v-show="(liveRoomInfo.isHost || liveRoomInfo.isLecturer) && liveRoomInfo.isLive" class="display-pdf"
          ref="embed">
          <!-- pdf课件展示 -->
          <embed v-if="pptUrl" :src="pptUrl" width="100%" height="100%" type="application/pdf" />
          <!-- 未选择课件时的展示 -->
          <div v-else class="no-data">
            <img src="../../../assets/images/live/folder-gray.png" alt="">
            <p>暂未选择课件，请选择课件或共享屏幕</p>
          </div>
        </div>
        <!-- （接收端）展示老师端屏幕共享的video -->
        <div v-show="!liveRoomInfo.isLive" class="display-video" id="remoteScreenVideo" ref="remoteScreenVideo"></div>

        <!--（推流端）本地预览摄像头的video -->
        <div v-show="liveRoomInfo.isLive" id="localVideo" ref="localVideo" @mousedown="md()"></div>
        <!-- (接收端) 显示远端摄像头的video -->
        <!-- v-show="!liveRoomInfo.isLive" -->
        <div id="remoteVideo" ref="remoteVideo" @mousedown="md()"></div>
        <!-- <div class="el-icon-full-screen" @click="fullscreen(2)"></div> -->
      </div>
      <div class="live-footer" v-if="liveRoomInfo.isHost || liveRoomInfo.isLecturer">
        <div class="footer-left">
          <div class="left-item" @click="drawerShow = true">
            <img src="../../../assets/images/live/folder.png" alt="">
            <p>选择课件</p>
          </div>
          <div class="left-item" @click="switchScreen()">
            <img style="transform: translateY(2px);" src="../../../assets/images/live/screensharing.png" alt="">
            <p>{{ screen ? "结束屏幕共享" : "屏幕共享" }}</p>
          </div>
        </div>
        <div class="footer-right">
          <div class="right-item" @click="changeCamera()">
            <img v-if="camera" src="../../../assets/images/live/camera-on.png" alt="">
            <img v-else src="../../../assets/images/live/camera-off.png" alt="">
            <p>摄像头</p>
          </div>
          <div class="right-item" @click="changeMicr()">
            <img v-if="micr" src="../../../assets/images/live/mic-on.png" alt="">
            <img v-else src="../../../assets/images/live/mic-off.png" alt="">
            <p>麦克风</p>
          </div>
          <div class="right-item" @click="fullscreen(1)">
            <div class="el-icon-full-screen"></div>
            <p>全屏</p>
          </div>
        </div>
      </div>
    </div>
    <div class="live-right" ref="right">
      <div class="tabs">
        <div class="tab-item" :class="{ 'tab-active': item.id === listActiveOption }" v-for="item in listOptions"
          :key="item.id" @click="changeListOption(item.id)">{{ item.title }}</div>
      </div>
      <div class="discussion-area" v-show="listActiveOption === '1'">
        <div class="message-list" ref="msgList">
          <div class="msg-item" v-for="(item, index) in msgList" :key="index">
            <div class="msg-name" :class="{ 'system-msg': item.type === 'system' }">
              <p>{{ item.name }}</p> <el-tag class="label" v-if="item.userId === liveRoomInfo.hostId"
                size="small">主持人</el-tag>
            </div>
            <div class="msg-time">{{ item.time }}</div>
            <div class="msg-message">{{ item.message }}</div>
          </div>
        </div>
        <div class="text-send">
          <el-input type="textarea" id="textarea" ref="textarea" v-model="messageForm.message"
            @keydown.native="sendMessage" :placeholder="'按下Enter发送消息\n按下Ctrl+Enter换行'" :rows="4" resize="none"></el-input>

          <div class="send-bottom">
            <div class="icon" id="emojiShow" @click="showEmo()">😀</div>
            <el-button type="primary" size="small" style="margin-right: 20px;" @click="sendMessage2()">发送</el-button>
          </div>
        </div>
        <div class='atList' id="atList" ref="atList" v-show="atListShow">
          <div class="at-item" v-for="item in userList" :key="item.user.id" @click="handleAt(item)">{{
            item.user.nickname || item.user.realname }}</div>
        </div>
        <div class="emoji-list" id="emoji" v-show="emoShow">
          <div class="emoji-item" v-for="(item, index) in emoji" :key="index" @click="addEmo(item)">{{ item }}</div>
        </div>
      </div>
      <div class="user-list" v-show="listActiveOption === '2'">
        <div class="user-search">
          <el-input type="small" size="small" v-model="filterKeyword" @blur="filterUser()"
            placeholder="输入名称搜索"></el-input>
          <div class="el-icon-search" @click="filterUser()"></div>
        </div>
        <div class="user-item" v-for="item in filterUserList" :key="item.user.id">
          <div class="user-left">
            <img :src="item.user.avatar" alt="暂无头像">
            <div class="item-info">
              <div class="item-name">
                <div class="item-name-val">
                  {{ item.user.nickname || item.user.realname }}
                </div>
                <el-tag class="label" v-if="item.is_manager === '2'" size="mini">主持人</el-tag>
                <el-tag class="label" v-if="item.is_manager === '1'" size="mini">讲师</el-tag>
              </div>
              <div class="item-id">ID {{ item.user.id }}</div>
            </div>
          </div>
          <div class="user-right">
            <div :id="item.user.id" style="display: none;" autoplay playsinline></div>
            <el-button v-if="liveRoomInfo.isHost && item.user.id !== liveRoomInfo.hostId && item.is_manager !== '1'"
              size="mini" type="primary" :loading="lecturerLoading && item.user.id === lectruerUserId"
              @click="setLecturerRequest(item)">设为讲师</el-button>
            <el-button class="lianxian"
              v-if="item.user.id !== liveRoomInfo.hostId && liveRoomInfo.isHost && item.is_manager !== '1'"
              :loading="lianmaiLoading && item.user.id === lianmaiUserId"
              :type="item.is_speaker === '1' ? 'warning' : 'primary'" size="mini" :disabled="item.is_speaker === '1'"
              @click="launchLianmai(item)">{{
                item.is_speaker === "1" ? "已连线" : "连线" }}</el-button>
            <el-button type="warning" size="mini"
              v-if="!liveRoomInfo.isHost && liveRoomInfo.userId === item.user.id && item.is_speaker === '1'"
              @click="stopLianmai()">取消连线</el-button>
          </div>
        </div>
      </div>
      <div class="fold" @click="foldExpand()">
        <div class="el-icon-caret-right"></div>
      </div>
    </div>
    <div v-if="fold" class="expand-right el-icon-caret-left" @click="foldExpand()"></div>


    <!-- 课件抽屉 -->
    <el-drawer :visible.sync='drawerShow' :size="800" direction="ltr">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>选择课件</div>
        <div class='drawer-title-btns'></div>
      </div>
      <div style='padding:20px;'>
        <div class="courseware-list">
          <div class="chapter" v-for="item in coursewareList" :key="item.id">
            {{ item.name }}
            <div class="joint" v-for="item2 in item.children" :key="item2.id">
              <div>{{ item2.name }}</div>
              <div class="ware-list" v-if="item2.ppt_info">
                <div v-for="item3 in item2.ppt_info" :key="item3.id" @click="openCourseware(item3)">{{ item3.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { Notification } from 'element-ui';
import "aliyun-webrtc-sdk";
import VERTC from '@volcengine/rtc';
import Cookies from "js-cookie";
import utils from "@/utils/tools.js";
import emoji from "@/assets/js/emoji.js";
export default {
  name: "liveRoom",
  data() {
    return {
      utils,
      emoji,
      // 音视频实例
      veWebrtc: null,
      // 预览状态
      preview: false,
      // 上课状态
      classStatus: false,
      // 上课按钮加载状态
      classLoading: false,
      // 推流状态
      liveStatus: false,
      // 屏幕共享状态
      screen: false,
      // 直播加载状态
      liveLoading: false,
      // 屏幕共享加载状态
      screenLoading: false,
      // 是否开启摄像头
      camera: true,
      // 开启麦克风
      micr: true,
      // 房间内用户列表
      userList: [],
      // 过滤关键字
      filterKeyword: "",
      // 过滤过后的用户列表
      filterUserList: [],
      // 消息列表
      msgList: [],
      // 直播间信息
      liveRoomInfo: {
        // 房间id/频道id/阿里用的
        roomId: "",
        // 直播间名称
        roomName: "",
        // 进入直播间人的昵称
        nickname: "",
        // 进入直播间人的id
        userId: "",
        // 进入直播间人的记录id
        recordId: "",
        // 是否是主持人
        isHost: false,
        // 是否正在连麦
        isSpeaker: false,
        // 讲师？
        isLecturer: false,
        // 主持人id
        hostId: "",
        // 直播标题
        liveTitle: "",
        // 直播列表id/自己平台用的id
        liveDetailID: "",
        // 课程id
        courseId: "",
        // 是否连麦等待弹窗
        awaitLianmai: false,
        // 是否成为讲师等待弹窗
        awaitLecturer: false
      },
      // 右下列表
      listOptions: [
        {
          id: "1",
          title: "讨论区"
        },
        {
          id: "2",
          title: "参与成员"
        }
      ],
      // 当前激活的区域展示
      listActiveOption: "1",
      // 右侧是否折叠
      fold: false,
      // 选择课件抽屉可见性
      drawerShow: false,
      // 课件列表
      coursewareList: [],
      // pptID
      pptID: "",
      // 课件url
      pptUrl: "",
      // 上课时间
      classTime: 0,
      // 上课的定时器
      classTimer: null,
      // move
      move: false,
      // 讨论区的消息表单
      messageForm: {
        message: "",
        reply_to_id: "0",
        isAt: false,
        atContent: ""
      },
      // @列表是否可见
      atListShow: false,
      // 连麦加载状态
      lianmaiLoading: false,
      // 连麦人id
      lianmaiUserId: "",
      // 连麦响应延迟器
      lianmaiTimer: null,
      // 讲师请求状态
      lecturerLoading: false,
      // 讲师人id
      lectruerUserId: "",
      // 设为讲师延迟器
      lectruerTimer: null,
      // emo表情
      emoShow: false,
      // websoket定时器
      socketTimer: null,
      // 是否弹出过体验窗
      experienceShow: false,
      // websocket实例
      socket: null,
      // 是否关闭socket
      closeSocket: false
    }
  },
  methods: {
    // *页面初始化相关代码
    // 初始化音视频
    initWebRTC() {
      this.veWebrtc = VERTC.createEngine('65d56beb52976a015b719f50');
      VERTC.isSupported().then(async () => {
        // 初始化音视频
        this.addWebRTCEvent();
        // 初始化消息列表
        this.initMsgList();
        // 初始化websocket
        this.initWebSocket();
        // 获取直播间详情
        await this.getLiveRoomDetail();
        // 获取课件列表
        this.initCourseware();
        // 加入房间（加入房间之前需要等待获取直播间详情，因为需要用到用户的id和昵称）
        this.joineroom();
      }).catch(() => {
        this.$message.error("您的浏览器不支持音视频相关功能");
      });
    },
    // 初始化消息列表
    initMsgList() {
      const { liveRoomInfo, msgList } = this;
      this.api.live.commentList({
        from_type: "0",
        page_size: 50,
        from_id: liveRoomInfo.liveDetailID,
        page: 1
      }).then(res => {
        const arr = res.list || [];
        arr.reverse();
        arr.forEach(item => {
          msgList.unshift({
            time: utils.timeFormatter(new Date(item.created_at * 1000), "yyyy.MM.dd hh:mm"),
            message: item.content,
            userId: item.user.id,
            name: item.user.nickname || item.user.realname,
            type: "user"
          });
        });
      });
    },
    // 初始化websocket
    initWebSocket() {
      const { liveRoomInfo } = this;
      const token = Cookies.get(this.constant.tokenName);
      this.socket = new WebSocket(`${this.constant.WSS}?token=${token}`);
      this.socket.onopen = () => {
        console.log("%cwebsocket打开", "color:lightpink");
      }
      this.socket.onmessage = (res) => {
        const socketData = JSON.parse(res.data);
        // 接收讨论区消息
        if (socketData?.data?.action === "commentSend") {
          this.updateMsgList({ message: socketData.data?.comment?.content, name: socketData.data?.user?.nickname || socketData.data?.user?.realname, userId: socketData.data?.user?.id, time: socketData.data?.comment?.created_at, type: "user" });
          this.rollingBottom();
        }
        // 接收直播间状态
        if (socketData?.data?.action === "getLiveRoomInfo") {
          if (socketData.data?.live_room?.status === "2" && !this.experienceShow) {
            this.experienceExpiration();
          }
        }
        // 收到主持人/讲师连麦申请
        if (socketData?.data?.action === "liveRoomApplySpeaker") {
          if (socketData.data?.to_user?.id === liveRoomInfo.userId) {
            this.isItConnectedMic();
          }
        }
        // 学生同意连麦
        if (socketData?.data?.action === "liveRoomAgreeApplySpeaker") {
          this.updateUserList();
          if (liveRoomInfo.isHost) {
            this.socket.send(JSON.stringify({ action: "liveRoomReceiveApplySpeakerConfirm", live_room_id: liveRoomInfo.liveDetailID, to_user_id: socketData.data?.from_user?.id }));
            this.agreeToConnectMicNotification();
          }
        }
        // 学生拒绝连麦
        if (socketData?.data?.action === "liveRoomRefuseApplySpeaker") {
          this.updateUserList();
          if (liveRoomInfo.isHost) {
            this.socket.send(JSON.stringify({ action: "liveRoomReceiveApplySpeakerConfirm", live_room_id: liveRoomInfo.liveDetailID, to_user_id: socketData.data?.from_user?.id }));
            this.refuseToConnectMicNotification();
          }
        }
        // 学生停止连麦
        if (socketData?.data?.action === "liveRoomStopSpeaker") {
          this.updateUserList();
        }
        // 收到主持人请求你成为讲师申请
        if (socketData?.data?.action === "liveRoomApplyLecturer") {
          if (socketData.data?.to_user?.id === liveRoomInfo.userId) {
            this.agreeOrRefuseLecturer();
          }
        }
        // 学生同意成为讲师
        if (socketData?.data?.action === "liveRoomAgreeApplyLecture") {
          this.updateUserList();
          if (liveRoomInfo.isHost) {
            this.socket.send(JSON.stringify({ action: "liveRoomReceiveApplyLectureConfirm", live_room_id: liveRoomInfo.liveDetailID, to_user_id: socketData.data?.from_user?.id }));
            this.agreeLecturerNotification();
          }
        }
        // 学生拒绝成为讲师
        if (socketData?.data?.action === "liveRoomRefuseApplyLecture") {
          this.updateUserList();
          if (liveRoomInfo.isHost) {
            this.socket.send(JSON.stringify({ action: "liveRoomReceiveApplyLectureConfirm", live_room_id: liveRoomInfo.liveDetailID, to_user_id: socketData.data?.from_user?.id }));
            this.refuseLecturerNotification();
          }
        }
      }
      this.socket.onclose = () => {
        if (!this.closeSocket) {
          console.log("%cwebsocket断线重连", "color:lightpink");
          const timer = setTimeout(() => {
            clearTimeout(timer);
            this.initWebSocket();
          }, 1000);
        }
      }
      // 轮询获取直播间状态
      clearInterval(this.socketTimer);
      this.socketTimer = setInterval(() => {
        this.socket.send(JSON.stringify({ action: "getLiveRoomInfo", live_room_id: liveRoomInfo.liveDetailID }));
      }, 5000);
    },
    // 初始化课件列表
    initCourseware() {
      const { liveRoomInfo } = this;
      this.api.course.courseDetail({
        id: liveRoomInfo.courseId
      }).then(res => {
        this.coursewareList = formatArrayDate(res.map_tree);
        this.coursewareList.forEach(item => {
          item?.children?.forEach(item2 => {
            item2?.ppt_info?.forEach(item3 => {
              if (item3.id === this.pptId) {
                this.pptUrl = item3.path;
              }
            });
          });
        });

        function formatArrayDate(arr) {
          const newArr = [];
          arr.forEach(item => {
            if (item?.children?.length) {
              newArr.push({ children: formatArrayDate(item.children), name: item.name });
            } else if (item?.ppt_info?.length) {
              delete item.children;
              newArr.push(item);
            }
          });
          return newArr;
        }

      });
    },
    // 添加事件监听
    addWebRTCEvent() {
      // 加入房间
      this.veWebrtc.on("onUserJoined", (event) => {
        this.updateUserList().then((list) => {
          const userInfo = list.find(item => item?.user?.id === event.userInfo.userId);
          this.updateMsgList({ message: (userInfo?.user.nickname || userInfo?.user.realname) + "加入房间" });
        });
      });
      // 离开房间
      this.veWebrtc.on("onUserLeave", (event) => {
        const userInfo = this.userList.find(item => item?.user?.id === event.userInfo.userId);
        this.updateMsgList({ message: (userInfo?.user.nickname || userInfo?.user.realname) + "离开房间" });
        this.updateUserList();
        this.unSubscribe(event?.userInfo?.userId, "all");
      });
      // 远程检测到推流（视频和音频）
      this.veWebrtc.on("onUserPublishStream", (event) => {
        this.getLiveRoomDetail();
        this.subscribe(event.userId, "video");
        this.updateUserList();
      });
      // 远程检测到关闭推流（视频和音频）
      this.veWebrtc.on("onUserUnpublishStream", (event) => {
        this.unSubscribe(event.userId, "video");
        this.updateUserList();
      });
      // 远程检测到推流（共享流）
      this.veWebrtc.on("onUserPublishScreen", (event) => {
        this.getLiveRoomDetail();
        this.subscribe(event.userId, "screen");
        this.updateUserList();
      });
      // 远程检测到关闭推流（共享流）
      this.veWebrtc.on("onUserUnpublishScreen", (event) => {
        this.unSubscribe(event.userId, "screen");
      });
    },
    // 获取直播间详情
    getLiveRoomDetail() {
      return new Promise((resolve, reject) => {
        const { liveRoomInfo } = this;
        this.api.live.getLiveRoomDetail({
          live_room_id: liveRoomInfo.liveDetailID
        }).then(res => {
          console.log("直播间详情信息", res);
          liveRoomInfo.isHost = res?.user?.is_manager === "2" ? true : false;
          liveRoomInfo.isLecturer = res?.user?.is_manager === "1" ? true : false;
          liveRoomInfo.nickname = res?.user?.nickname || res?.user?.realname;
          liveRoomInfo.userId = res?.user?.id;
          liveRoomInfo.liveTitle = res?.info?.name;
          liveRoomInfo.hostId = res?.info?.user_id;
          liveRoomInfo.roomName = res?.info?.name;
          // 是否正在直播
          liveRoomInfo.isLive = res?.live_room_user?.is_live === "1";
          // 是否正在连麦
          liveRoomInfo.isSpeaker = res?.live_room_user?.is_speaker === "1";
          // 等待状态赋值
          liveRoomInfo.awaitLianmai = res?.live_room_user?.apply_speaker_verify_status === "1";
          liveRoomInfo.awaitLecturer = res?.live_room_user?.apply_lecture_verify_status === "1";
          this.classStatus = res?.info?.status === "1" ? true : false;
          this.camera = res?.live_room_user?.is_open_video === "1";
          this.micr = res?.live_room_user?.is_open_audio === "1";
          this.screen = res?.live_room_user?.is_open_share_screen === "1";
          this.pptId = res?.live_room_user?.source_id;
          resolve();
        }).catch(e => {
          reject(e);
        });
      });
    },


    // *更新用户列表、消息列表相关代码
    // 更新用户列表
    updateUserList() {
      const { liveRoomInfo } = this;
      return new Promise((resolve) => {
        this.api.live.getLiveRoomUserList({
          live_room_id: liveRoomInfo.liveDetailID,
          page_size: 1000
        }).then(res => {
          // console.log("平台返回的用户列表", res);
          this.userList = res.list || [];
          this.filterUser();
          clearTimeout(this.timer);
          this.timer = null;
          resolve(res.list);
        });
      });
    },
    // 更新消息列表
    updateMsgList(event = { type: "", message: "", name: "", userId: "", time: null }) {
      const { msgList } = this;
      const date = new Date();
      msgList.push({
        time: event.time ? utils.timeFormatter(new Date(event.time * 1000), "yyyy.MM.dd hh:mm") : utils.timeFormatter(date, "yyyy.MM.dd hh:mm"),
        message: event.message,
        userId: event.userId,
        name: event.name ? event.name : "[系统消息]",
        type: event.type || "system"
      });
    },


    // *切换上课状态相关代码
    // 切换上课状态
    switchClass() {
      if (this.classStatus) {
        this.endClass();
      } else {
        this.attendClass();
      }
    },
    // 上课
    attendClass() {
      const { liveRoomInfo } = this;
      this.classLoading = true;
      this.api.live.attendClass({
        live_room_id: liveRoomInfo.liveDetailID
      }).then(() => {
        this.classLoading = false;
        this.classStatus = true;
      }).catch(() => {
        this.classLoading = false;
      });
    },
    // 下课
    endClass() {
      const { liveRoomInfo } = this;
      this.classLoading = true;
      MessageBox.confirm("您确定要解散课堂吗", "提示", {
        type: "warning"
      }).then(() => {
        this.api.live.endClass({
          live_room_id: liveRoomInfo.liveDetailID
        }).then(() => {
          this.classLoading = false;
          this.stopStreaming();
        }).catch(() => {
          this.classLoading = false;
        });
      }).catch(() => {
        this.classLoading = false;
      });
    },

    // *预览相关代码
    // 开启、关闭预览
    switchPreview() {
      if (this.preview) {
        this.stopPreview();
      } else {
        this.startPreview();
      }
    },
    // 开始预览
    startPreview() {
      const { liveRoomInfo } = this;
      this.$nextTick(() => {
        // 渲染本地流到video  0：主流  1：屏幕流
        try {
          this.veWebrtc.setLocalVideoPlayer(0, {
            renderDom: 'localVideo',
            userId: liveRoomInfo.userId,
          });
          this.preview = true;
          console.log("%c开启预览成功", 'color: lightgreen;');
        } catch (e) {
          console.error("开启预览失败", e);
        }
      });
    },
    // 停止预览
    stopPreview() {
      const { liveRoomInfo } = this;
      try {
        this.veWebrtc.setLocalVideoPlayer(0, {
          renderDom: null,
          userId: liveRoomInfo.userId,
        });
        this.preview = false;
        console.log("%c关闭预览成功", 'color: lightgreen;');
      } catch (e) {
        console.error("关闭预览失败", e);
      }
    },


    // *房间相关代码
    // 加入房间
    async joineroom() {
      const { liveRoomInfo } = this;
      // 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
      const authInfo = await this.generateVeRtcAuthInfo();
      // 加入房间（请求平台）
      this.api.live.enterLiveRoom({
        live_room_id: liveRoomInfo.liveDetailID
      }).then(res => {
        liveRoomInfo.recordId = res.live_room_user.id;
        this.updateUserList();
        // 加入房间（请求火山）
        this.veWebrtc.joinRoom(authInfo.token, authInfo.channel, authInfo.userInfo, {
          // 不自动推流
          isAutoPublish: false,
          // 不自动订阅音频
          isAutoSubscribeAudio: false,
          // 不自动订阅视频
          isAutoSubscribeVideo: false,
        }).then(() => {
          console.log("%c加入直播间成功", 'color: lightgreen;');
          // 如果是直播状态，接着给老子开播
          if (liveRoomInfo.isLive && (liveRoomInfo.isHost || liveRoomInfo.isLecturer)) {
            console.log("%c续播", "color:lightgreen");
            this.startStreaming();
          } else if (!liveRoomInfo.isHost && !liveRoomInfo.isLecturer && liveRoomInfo.isSpeaker) {
            // 如果是连麦状态接着给老子连麦
            console.log("%c续麦", "color:lightgreen");
            this.startLianmai();
          }
          // 等待状态处理
          if (liveRoomInfo.awaitLecturer && !liveRoomInfo.isLecturer && !liveRoomInfo.isHost) {
            console.log("%cawait讲师", "color:lightgreen");
            this.agreeOrRefuseLecturer();
          }
          if (liveRoomInfo.awaitLianmai && !liveRoomInfo.isLecturer && !liveRoomInfo.isHost) {
            console.log("%cawait连麦", "color:lightgreen");
            this.isItConnectedMic();
          }
        });
      }).catch(e => {
        console.error("加入直播间失败", e);
        this.$Message.error("进入直播间失败");
      });
    },
    // 离开房间
    leaveroom() {
      const { liveRoomInfo } = this;
      this.api.live.leaveLiveRoom({
        live_room_id: liveRoomInfo.liveDetailID,
        live_room_user_id: liveRoomInfo.recordId
      }).then(() => {
        console.log("%c离开房间成功", 'color: lightgreen;');
      }).catch(e => {
        console.error("离开直播间失败", e);
      });
    },
    // 获取用户鉴权信息
    generateVeRtcAuthInfo() {
      const { liveRoomInfo } = this;
      return new Promise((resolve, reject) => {
        this.api.live.getRoomToken({
          live_room_id: liveRoomInfo.liveDetailID
        }).then((res) => {
          resolve({
            appid: "65d56beb52976a015b719f50",
            token: res.token,
            channel: liveRoomInfo.roomId,
            userInfo: {
              userId: liveRoomInfo.userId
            }
          });
        }).catch(() => {
          reject();
        });
      });

    },


    // *推流相关代码
    // 开始、结束直播
    switchLive() {
      if (this.liveStatus) {
        this.stopStreaming();
      } else {
        this.startStreaming();
      }
    },
    // 开始推流
    startStreaming() {
      const { liveRoomInfo } = this;
      if (!this.classStatus) {
        this.$Message.error("请先开始上课再开始直播");
        return;
      }
      this.liveLoading = true;
      // 开启预览
      liveRoomInfo.isLive = true;
      if (!this.preview && this.camera) {
        this.startPreview();
      }
      // 推流调接口
      this.api.live.startStreaming({
        live_room_id: liveRoomInfo.liveDetailID,
        is_open_audio: this.micr,
        is_open_video: this.camera
      }).then(() => {
        /*
          如果平台接口成功，调阿里推流
          里面的数字代表含义1：只控制音频  2：只控制视频  3：都控制
        */
        this.veWebrtc.publishStream(3).then(() => {
          // 根据状态是否开启关闭摄像头/麦克风
          if (this.camera) {
            this.veWebrtc.setVideoEncoderConfig({
              width:500,
              height:500,
              frameRate:15.1,
              maxKbps:800
            }).then(()=>{
              this.veWebrtc.startVideoCapture().then(res=>{
                console.log("视频采集参数",res);
              });
            });
          } else {
            this.veWebrtc.stopVideoCapture();
          }
          if (this.micr) {
            this.veWebrtc.startAudioCapture();
          } else {
            this.veWebrtc.stopAudioCapture();
          }
          this.liveStatus = true;
          this.liveLoading = false;
          // 如果开启了屏幕共享，打开屏幕共享（断线/刷新用到，其他时候用不到）
          if (this.screen) {
            this.startScreenSharing();
          }
          // 上课计时
          this.classroomTiming();
          // this.getLiveRoomDetail();
          console.log("%c推流成功", 'color: lightgreen;');
        });
      }).catch(e => {
        liveRoomInfo.isLive = false;
        this.liveLoading = false;
        this.stopPreview();
        console.error("推流失败", e);
      });
    },
    // 停止推流 leaveRoom是否调离开房间
    stopStreaming(leaveRoom = false) {
      const { liveRoomInfo } = this;
      this.liveLoading = true;
      this.api.live.stopStreaming({
        live_room_id: liveRoomInfo.liveDetailID
      }).then(() => {
        liveRoomInfo.isLive = false;
        this.veWebrtc.stopVideoCapture();
        this.veWebrtc.stopAudioCapture();
        this.veWebrtc.unpublishStream(3).then(() => {
          this.liveStatus = false;
          this.liveLoading = false;
          this.screen = false;
          this.stopPreview();
          clearInterval(this.classTimer);
          this.classTimer = null;
          if (leaveRoom) {
            this.veWebrtc.leaveRoom();
            this.leaveroom();
          }
          console.log("%c停止推流成功", 'color: lightgreen;');
        });
        if (this.screen) {
          this.stopScreenSharing();
        }
      });
      this.api.live.liveStatusSync({
        live_room_id: liveRoomInfo.liveDetailID,
        live_room_user_id: liveRoomInfo.recordId,
        field: "is_open_share_screen",
        value: "0"
      });
    },


    // *共享屏幕相关代码
    // 切换屏幕共享状态
    switchScreen() {
      const { screen } = this;
      if (screen) {
        this.stopScreenSharing();
      } else {
        this.startScreenSharing();
      }
    },
    // 开始共享屏幕
    startScreenSharing() {
      const { liveStatus, liveRoomInfo } = this;
      if (!liveStatus) {
        this.$Message.error("请先开启直播再开启屏幕共享");
        return;
      }
      this.screenLoading = true;
      /*
        使用内部采集模块，采集当前屏幕视频流，用于共享
        调用此方法仅开启屏幕流视频采集，不会发布采集到的视频。发布屏幕流视频需要调用 publishScreen
        你问我为什么？我也不知道，SDK这么要求的
      */
      this.veWebrtc.startScreenCapture({ enableAudio: true }).then(() => {
        // 里面的值 1：只控制音频  2：只控制视频  3：同时控制音频和视频
        this.veWebrtc.publishScreen(3);
        this.screen = true;
        this.screenLoading = false;
        // 做记录
        this.api.live.liveStatusSync({
          live_room_id: liveRoomInfo.liveDetailID,
          live_room_user_id: liveRoomInfo.recordId,
          field: "is_open_share_screen",
          value: this.screen ? "1" : "0"
        });
      }).catch(() => {
        this.screenLoading = false;
        this.screen = false;
        this.$Message.error("屏幕共享失败");
      });
    },
    // 停止共享屏幕
    stopScreenSharing() {
      const { liveRoomInfo } = this;
      this.screenLoading = true;
      this.veWebrtc.unpublishScreen(3).then(() => {
        this.veWebrtc.stopScreenCapture();
        this.screen = false;
        this.screenLoading = false;
        // 做记录
        this.api.live.liveStatusSync({
          live_room_id: liveRoomInfo.liveDetailID,
          live_room_user_id: liveRoomInfo.recordId,
          field: "is_open_share_screen",
          value: this.screen ? "1" : "0"
        });
      }).catch(() => {
        this.screenLoading = false;
      });
    },


    // *订阅相关代码
    // 订阅视频和音频
    subscribe(userId, type) {
      if (!userId) return;
      // 是否是主持人或者讲师
      let isHostOrLecturer = false;
      // 判断这个推流的人是主持人/讲师还是学生
      for (let i in this.userList) {
        if (this.userList[i]?.user?.id === userId) {
          // 如果是主持人或者是讲师
          if (this.userList[i].is_manager === "1" || this.userList[i].is_manager === "2") {
            isHostOrLecturer = true;
          }
          break;
        }
      }
      // 判断是订阅音视频流还是屏幕流
      if (type === "video") {
        console.log("video", userId, type);
        this.veWebrtc.subscribeStream(userId, 3).then(() => {
          this.$nextTick(() => {
            if (isHostOrLecturer) {
              console.log('%c订阅主持人/讲师的音视频流', 'color: lightgreen;');
              // 有人推流，自己必须停止推流
              if (this.liveStatus) {
                this.stopStreaming();
              }
              // 如果是主持或者讲师人推流，那么需要设置展示主持人的摄像头和video
              // 渲染到页面（0：主流  1：屏幕流）
              this.veWebrtc.setRemoteVideoPlayer(0, {
                renderDom: "remoteVideo",
                userId: userId,
              });
            } else {
              console.log("%c订阅非主持人连麦音频流", 'color: lightgreen;');
              this.veWebrtc.setRemoteVideoPlayer(0, {
                renderDom: userId,
                userId: userId,
              });
            }
          });
        }).catch(e => {
          console.log("订阅失败,错误信息", e);
        });
      } else if (type === "screen") {
        this.veWebrtc.subscribeScreen(userId, 3).then(() => {
          this.$nextTick(() => {
            console.log('%c订阅主持人/讲师的屏幕流', 'color: lightgreen;');
            // 渲染到页面（0：主流  1：屏幕流）
            this.veWebrtc.setRemoteVideoPlayer(1, {
              renderDom: "remoteScreenVideo",
              userId: userId
            });
          });
        });
      }
    },

    // 取消订阅
    unSubscribe(userId, type) {
      if (!userId) return;
      const unSubscribeVideo = () => {
        // this.veWebrtc.unsubscribeStream(userId, 3).then(() => {
        this.veWebrtc.setRemoteVideoPlayer(0, {
          renderDom: "",
          userId: userId
        });
        // });
      }
      const unSubscribeScreen = () => {
        // this.veWebrtc.unsubscribeScreen(userId, 3).then(() => {
        this.veWebrtc.setRemoteVideoPlayer(1, {
          renderDom: "",
          userId: userId
        });
        // });
      }
      if (type === "video") {
        unSubscribeVideo();
      } else if (type === "screen") {
        unSubscribeScreen();
      } else if (type === "all") {
        unSubscribeVideo();
        unSubscribeScreen();
      }
    },


    // *相机、麦克风采集相关代码
    // 开启、关闭相机采集
    changeCamera() {
      const { liveRoomInfo } = this;
      this.camera = !this.camera;
      if (this.liveStatus) {
        if (this.camera) {
          this.startPreview();
        } else {
          this.stopPreview();
        }
      }
      // 传true是停止采集 传false是不停止采集
      if (this.camera) {
        this.veWebrtc.startVideoCapture();
      } else {
        this.veWebrtc.stopVideoCapture();
      }
      // 做记录
      this.api.live.liveStatusSync({
        live_room_id: liveRoomInfo.liveDetailID,
        live_room_user_id: liveRoomInfo.recordId,
        field: "is_open_video",
        value: this.camera ? "1" : "0"
      });
    },
    // 开启、关闭麦克风采集
    changeMicr() {
      const { liveRoomInfo } = this;
      this.micr = !this.micr;
      if (this.micr) {
        this.veWebrtc.startAudioCapture();
      } else {
        this.veWebrtc.stopAudioCapture();
      }
      // 做记录
      this.api.live.liveStatusSync({
        live_room_id: liveRoomInfo.liveDetailID,
        live_room_user_id: liveRoomInfo.recordId,
        field: "is_open_audio",
        value: this.micr ? "1" : "0"
      });
    },


    // *连麦相关代码
    // 发起连麦
    launchLianmai(item) {
      const { liveRoomInfo } = this;
      // !同一时间只能向一个人发起连麦 避免死锁
      if (this.lianmaiLoading) {
        this.$Message.error("请等待响应");
        return;
      }
      this.lianmaiLoading = true;
      this.lianmaiUserId = item.user.id;
      this.socket.send(JSON.stringify({ action: "liveRoomApplySpeaker", live_room_id: liveRoomInfo.liveDetailID, from_type: "0", to_user_id: item.user.id }));
      this.lianmaiTimer = setTimeout(() => {
        this.lianmaiLoading = false;
        this.lianmaiUserId = "";
        clearTimeout(this.lianmaiTimer);
        Notification.warning({
          title: "通知",
          message: "学生暂未响应"
        });
      }, 10000);
    },
    // 接受连麦
    startLianmai() {
      const { liveRoomInfo } = this;
      this.veWebrtc.configLocalAudioPublish = true;
      this.veWebrtc.publishStream(1).then(() => {
        this.veWebrtc.startAudioCapture(); // 开启采集
        console.log("%c连麦推流成功", "color:lightskyblue");
      });
      this.socket.send(JSON.stringify({ action: "liveRoomAgreeApplySpeaker", live_room_id: liveRoomInfo.liveDetailID, from_type: "0", to_user_id: liveRoomInfo.hostId }));
    },
    // 拒绝连麦
    rejectLianmai() {
      const { liveRoomInfo } = this;
      this.socket.send(JSON.stringify({ action: "liveRoomRefuseApplySpeaker", live_room_id: liveRoomInfo.liveDetailID, from_type: "0", to_user_id: liveRoomInfo.hostId }));
    },
    // 结束连麦
    stopLianmai() {
      const { liveRoomInfo } = this;
      this.veWebrtc.stopAudioCapture();
      this.veWebrtc.unpublishStream(1);
      this.socket.send(JSON.stringify({ action: "liveRoomStopSpeaker", live_room_id: liveRoomInfo.liveDetailID }));
    },
    // 学生连麦提示
    isItConnectedMic() {
      MessageBox.confirm("主持人请求与你连麦", "提示", {
        confirmButtonText: "接受",
        cancelButtonText: "拒绝",
        showClose: false,
        closeOnClickModal: false
      }).then(() => {
        this.startLianmai();
      }).catch(() => {
        this.rejectLianmai();
      });
    },
    // 同意连麦通知
    agreeToConnectMicNotification() {
      console.warn("连线成功");
      this.lianmaiUserId = "";
      this.lianmaiLoading = false;
      Notification.success({
        title: "通知",
        message: "连线成功"
      });
      clearTimeout(this.lianmaiTimer);
    },
    // 拒绝连麦通知
    refuseToConnectMicNotification() {
      this.lianmaiUserId = "";
      this.lianmaiLoading = false;
      Notification.warning({
        title: "通知",
        message: "学生拒绝连线"
      });
      clearTimeout(this.lianmaiTimer);
    },


    // *设为讲师相关代码
    // 发起设为讲师请求
    setLecturerRequest(item) {
      const { liveRoomInfo } = this;
      // !同一时间只能向一个人发成为讲师 避免死锁
      if (this.lecturerLoading) return;
      this.lecturerLoading = true;
      this.lectruerUserId = item.user.id;
      this.socket.send(JSON.stringify({ action: "liveRoomApplyLecturer", live_room_id: liveRoomInfo.liveDetailID, from_type: "0", to_user_id: item.user.id }));
      this.lectruerTimer = setTimeout(() => {
        this.lecturerLoading = false;
        this.lectruerUserId = "";
        clearTimeout(this.lectruerTimer);
        Notification.warning({
          title: "通知",
          message: "学生暂未响应"
        });
      }, 10000);
    },
    // 接受
    agreeLecturer() {
      const { liveRoomInfo } = this;
      this.socket.send(JSON.stringify({ action: "liveRoomAgreeApplyLecture", live_room_id: liveRoomInfo.liveDetailID, from_type: "0", to_user_id: liveRoomInfo.hostId }));
      this.getLiveRoomDetail();
    },
    // 拒绝
    refuseLecturer() {
      const { liveRoomInfo } = this;
      this.socket.send(JSON.stringify({ action: "liveRoomRefuseApplyLecture", live_room_id: liveRoomInfo.liveDetailID, from_type: "0", to_user_id: liveRoomInfo.hostId }));
    },
    // 学生端设为主持人提示
    agreeOrRefuseLecturer() {
      MessageBox.confirm("主持人请求将你设为讲师", "提示", {
        confirmButtonText: "接受",
        cancelButtonText: "拒绝",
        showClose: false,
        closeOnClickModal: false
      }).then(() => {
        this.agreeLecturer();
      }).catch(() => {
        this.refuseLecturer();
      });
    },
    // 主持人端收到接受通知
    agreeLecturerNotification() {
      this.lectruerUserId = "";
      this.lecturerLoading = false;
      Notification.success({
        title: "通知",
        message: "设为讲师成功"
      });
      clearTimeout(this.lectruerTimer);
    },
    // 主持人端收到拒绝通知
    refuseLecturerNotification() {
      this.lectruerUserId = "";
      this.lecturerLoading = false;
      Notification.warning({
        title: "通知",
        message: "拒绝成为讲师"
      });
      clearTimeout(this.lectruerTimer);
    },

    // *销毁相关代码
    // 离开页面停止推流，离开房间，销毁实例
    funeralAffairs() {
      this.stopPreview();
      this.stopStreaming(true);
      clearInterval(this.classTimer);
      clearInterval(this.socketTimer);
    },
    returnBack() {
      // const { liveRoomInfo } = this;
      // // 如果不是主持人，直接退出，没有提醒
      // if (!liveRoomInfo.isHost) {
      //   this.$router.back();
      //   return;
      // }
      // MessageBox.confirm("返回将解散课堂直播间", "您确定要返回吗？", {
      //   type: "warning"
      // }).then(() => {
      //   this.$router.back();
      // });
      // this.leaveroom();
      // return;
      this.$router.back();
    },

    // *消息通讯相关代码
    // 键盘发送
    sendMessage(event) {
      const { liveRoomInfo, messageForm } = this;
      // 发送（回车 && 没有按下ctrl）
      if (event.keyCode === 13 && !event.ctrlKey) {
        this.socket.send(JSON.stringify({ action: "commentSend", from_id: liveRoomInfo.liveDetailID, from_type: "0", ...messageForm }));
        messageForm.message = "";
        messageForm.reply_to_id = "0";
        event.preventDefault();
      }
      // 换行（ctrl+回车）
      if (event.keyCode === 13 && event.ctrlKey) {
        messageForm.message += "\n";
      }
      // ?忘了什么意思了。。。
      if (event.keyCode === 50 && event.shiftKey) {
        this.emoShow = false;
        this.atListShow = true;
      } else {
        this.atListShow = false;
      }
      // 删除
      if (event.keyCode === 8) {
        if (messageForm.isAt) {
          const textarea = document.getElementById("textarea");
          const atLength = messageForm.atContent.length;
          const selectionStart = textarea.selectionStart;
          if (selectionStart - 1 <= atLength) {
            messageForm.reply_to_id = "0";
            messageForm.isAt = false;
            messageForm.atContent = "";
            messageForm.message = "";
          }
        }
      }
    },
    // 按钮发送
    sendMessage2() {
      const { liveRoomInfo, messageForm } = this;
      this.socket.send(JSON.stringify({ action: "commentSend", from_id: liveRoomInfo.liveDetailID, from_type: "0", ...messageForm }));
      messageForm.message = "";
      messageForm.reply_to_id = "0";
    },
    // 处理@消息
    handleAt(item) {
      const { messageForm } = this;
      messageForm.message += item.user.nickname || item.user.realname;
      messageForm.message += " ";
      messageForm.reply_to_id = item?.user?.id;
      messageForm.isAt = true;
      messageForm.atContent = `@${item.user.nickname || item.user.realname}`;
      this.atListShow = false;
      this.$refs.textarea.focus();
    },


    // *emo表情处理
    showEmo() {
      this.atListShow = false;
      this.emoShow = true;
    },
    addEmo(emoji) {
      const { messageForm } = this;
      messageForm.message += emoji;
      this.emoShow = false;
      this.$refs.textarea.focus();
    },

    // 点击文档其他区域关闭emo和at
    processingClicks(event) {
      if (event.target.id === "emojiShow") {
        this.atListShow = false;
        this.emoShow = true;
      } else if (event.target.id !== "emoji") {
        this.emoShow = false;
        this.emoShow = false;
      }
    },

    // *过滤用户列表
    filterUser() {
      const { filterKeyword, userList } = this;
      if (filterKeyword) {
        const reg = new RegExp(filterKeyword);
        this.filterUserList = userList.filter(item => {
          return reg.test(item.user.nickname) || reg.test(item.user.realname);
        });
      } else {
        this.filterUserList = userList;
      }
    },

    // *消息列表滚动到底部
    rollingBottom() {
      // !注意，必须等dom更新完毕之后才能执行
      this.$nextTick(() => {
        this.$refs.msgList.scrollTop = this.$refs.msgList.scrollHeight;
      });
    },

    // *体验时长到期
    experienceExpiration() {
      this.experienceShow = true;
      MessageBox.confirm("直播已结束", "提示", {
        type: "warning",
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false
      }).then(() => {
        this.$router.back();
      });
    },


    // *杂项
    // 切换右侧展示列表
    changeListOption(id) {
      this.listActiveOption = id;
    },

    // 打开课件
    openCourseware(item) {
      const { liveRoomInfo } = this;
      if (!liveRoomInfo.isLive) {
        this.drawerShow = false;
        this.$Message.error("请先开启直播");
        return;
      }
      this.pptUrl = item.path;
      console.log(item);
      this.drawerShow = false;
      this.api.live.liveStatusSync({
        live_room_id: liveRoomInfo.liveDetailID,
        live_room_user_id: liveRoomInfo.recordId,
        field: "source_id",
        value: item.id
      });
    },

    // 全屏
    fullscreen(type) {
      // type 1共享区域 2学生端视频
      if (type === 1) {
        utils.fullscreen(this.$refs.embed);
      } else if (type === 2) {
        utils.fullscreen(this.$refs.remoteVideo);
      }
    },
    // 展开折叠右侧
    foldExpand() {
      if (this.fold) {
        this.$refs.right.style.width = "350px";
        this.fold = false;
      } else {
        this.$refs.right.style.width = "0px";
        this.fold = true;
      }
    },

    // *上课计时
    classroomTiming() {
      this.classTimer = setInterval(() => {
        this.classTime++;
      }, 1000);
    },


    // *移动相关代码
    md() {
      this.move = true;
    },
    mv(e) {
      if (this.move) {
        if (this.liveRoomInfo.isLive) {
          this.$refs.localVideo.style.left = `${e.pageX - 115}px`;
          this.$refs.localVideo.style.top = `${e.pageY - 145}px`;
        } else {
          this.$refs.remoteVideo.style.left = `${e.pageX - 115}px`;
          this.$refs.remoteVideo.style.top = `${e.pageY - 145}px`;
        }
      }
    },
    mp() {
      this.move = false;
    }
  },
  created() {
    this.liveRoomInfo.roomId = this.$route.query.roomId;
    this.liveRoomInfo.liveDetailID = this.$route.query.id;
    this.liveRoomInfo.courseId = this.$route.query.courseId;
    this.initWebRTC();
    window.addEventListener("mousemove", this.mv);
    window.addEventListener("mouseup", this.mp);
    window.addEventListener("click", this.processingClicks);
  },
  beforeDestroy() {
    this.closeSocket = true;
    this.socket.close();
    this.funeralAffairs();
    window.removeEventListener("mousemove", this.mv);
    window.removeEventListener("mouseup", this.mp);
    window.removeEventListener("click", this.processingClicks);
  }
}
</script>

<style lang="scss" scoped>
.live {
  height: 100vh;
  overflow: hidden;
  display: flex;

  .live-left {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .header-left {
      display: flex;
      align-items: center;

      .live-name {
        font-size: 20px;
        margin-left: 10px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .live-header {
      height: 80px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;

      .header-right {
        display: flex;
        align-items: center;

        .class-time {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          margin-right: 40px;
        }
      }
    }

    .screen-sharing-display-area {
      flex-grow: 1;
      background-color: #ECEDF2;
      position: relative;

      .display-video {
        width: 100%;
        height: 100%;
      }

      .display-pdf {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .no-data {
          text-align: center;

          >img {
            width: 50px;
            margin-bottom: 18px;
          }

          >p {
            font-size: 14px;
            color: #666666;
          }
        }
      }

      #localVideo,
      #remoteVideo {
        width: 230px;
        height: 130px;
        background-color: black;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;

        &:hover {
          cursor: move;
        }

        // >video {
        //   width: 100%;
        //   height: 100%;
        // }

        >.el-icon-full-screen {
          position: absolute;
          bottom: 5px;
          right: 5px;
          color: white;
          cursor: pointer;
          font-size: 16px;
        }
      }

      #localVideo {
        z-index: 20;
      }
    }

    .live-footer {
      height: 80px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;

      .footer-left {
        display: flex;
        align-items: center;

        .left-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 40px;
          cursor: pointer;

          >img {
            width: 24px;
          }

          >p {
            font-size: 12px;
            color: #666666;
          }
        }
      }

      .footer-right {
        display: flex;
        align-items: center;

        .right-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          margin-left: 30px;

          >img {
            width: 24px;
          }

          >p {
            font-size: 12px;
            color: #666666;
          }

          .el-icon-full-screen {
            font-size: 24px;
            color: #4a79ff;
          }
        }
      }
    }
  }

  .live-right {
    width: 350px;
    flex-shrink: 0;
    border-left: 1px solid #EEEEEE;
    background-color: white;
    position: relative;
    overflow: hidden;
    transition: .2s;

    .tabs {
      height: 80px;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .tab-item {
        font-size: 16px;
        font-weight: bold;
        color: #666666;
        cursor: pointer;
        position: relative;
      }

      .tab-active {
        color: #5782FF;

        &::after {
          content: "";
          display: block;
          width: 46px;
          height: 4px;
          background-color: #5782FF;
          position: absolute;
          bottom: -29px;
          left: calc(50% - 23px);
        }
      }
    }

    .discussion-area {
      height: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      position: relative;

      .message-list {
        flex-grow: 1;
        padding: 20px;
        overflow-y: auto;

        .msg-item {
          margin-bottom: 20px;

          .msg-name {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            display: flex;
            align-items: center;

            >p {
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .el-tag {
              margin-left: 5px;
            }
          }

          .msg-time {
            font-size: 14px;
            color: #999999;
          }

          .msg-message {
            font-size: 16px;
            color: #333333;
          }

          .system-msg {
            color: #4a79ff;
          }
        }

      }

      .text-send {
        height: 140px;
        background-color: white;
        border-top: 1px solid #EEEEEE;
        flex-shrink: 0;

        ::v-deep .el-textarea>textarea {
          border: none;
        }

        .send-bottom {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .icon {
            font-size: 20px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }

      .atList {
        position: absolute;
        width: 100%;
        bottom: 140px;
        right: 0;
        border-top: 1px solid #EEEEEE;
        font-size: 14px;
        user-select: none;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);

        .at-item {
          text-align: left;
          cursor: pointer;
          padding: 3px 20px;

          &:hover {
            background-color: #eee;
          }
        }

      }

      .emoji-list {
        position: absolute;
        width: 100%;
        bottom: 140px;
        right: 0;
        border-top: 1px solid #EEEEEE;
        font-size: 14px;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);
        font-size: 30px;
        padding: 20px 0;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-items: center;
        max-height: 230px;
        overflow-y: auto;

        .emoji-item {
          cursor: pointer;
        }
      }
    }

    .user-list {
      padding: 20px;

      .user-search {
        position: relative;

        .el-icon-search {
          position: absolute;
          right: 0;
          top: 0;
          height: 32px;
          width: 32px;
          text-align: center;
          line-height: 32px;
          color: #4a79ff;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .user-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #eee;

        &:nth-last-child(1) {
          border-bottom: none;
        }

        .user-left {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          flex-grow: 1;

          >img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
            user-select: none;
            pointer-events: none;
          }

          .item-info {
            .item-name {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: bold;
              color: #333333;

              .item-name-val {
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .el-tag {
                margin-left: 10px;
              }
            }

            .item-id {
              font-size: 12px;
              color: #666666;
            }
          }
        }

        .user-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .lianxian {
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }
    }

    .fold {
      position: absolute;
      left: 0;
      top: 50%;
      font-size: 16px;
      cursor: pointer;
    }

    .fold-left {
      left: -20px;
    }
  }

  .expand-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    top: 50%;
    font-size: 16px;
    cursor: pointer;
  }

  .courseware-list {
    .chapter {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      background-color: #eee;
      padding: 10px;
      border-radius: 4px;

      .joint {
        padding-left: 10px;
        font-size: 16px;

        .ware-list {
          font-weight: normal;
          padding-left: 10px;

          >div {
            cursor: pointer;

            &:hover {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

.black-btn {
  background-color: #333333;
  border-radius: 4px;
  color: white;
}
</style>
